<template>
  <div class="navigation__container row">
    <Dashboard v-if="userId" />
    <Authentication v-else @showLanguages="showLanguages = true" />

    <LanguagesPopup v-if="showLanguages" @close="showLanguages = false" />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import Dashboard from './components/Dashboard.vue'
import Authentication from './components/Authentication.vue'
import LanguagesPopup from './components/LanguagesPopup.vue'

export default {
  name: 'MainNavbar',
  components: { Dashboard, Authentication, LanguagesPopup },
  setup() {
    const store = useStore()
    const showLanguages = ref(false)
    const device = computed(() => store.state.screen.device)
    const userId = computed(() => store.state.user.id)

    return {
      showLanguages,
      device,
      userId,
    }
  },
}
</script>

<style lang="scss" scoped></style>
