<template>
  <div class="settings__options report__list column">
    <div @click="$emit('back')" class="link highlight back row">
      <i class="fa-solid fa-angle-left"></i>
      <span>{{ t('Back') }}</span>
    </div>

    <Search @update="filterReports($event)" @reset="resetSearch" class="search" />

    <Category
      v-for="report of filteredReports.length ? filteredReports : reports"
      :key="report._id"
      @click="setEditReport(report)"
      :href="`/reports/edit-report/${report._id}`"
      icon="fa-solid fa-pen"
      :text="report.name"
      :subCategory="true"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import Search from '@components/input/Search.vue'
import Category from '@components/navigation/dashboard/components/Category.vue'

export default {
  name: 'ReportList',
  emits: ['back'],
  components: { Search, Category },
  setup() {
    const store = useStore()
    const filteredReports = ref([])
    const reports = computed(() => {
      const reports = store.getters.getReports

      return reports.filter(
        (r) =>
          r.permission.length <= 0 ||
          r.permission.some((p) => p._id === store.state.user?.role?._id)
      )
    })

    const setEditReport = (report) => {
      store.commit('setEditReport', report)
    }

    const filterReports = (filter) => {
      filteredReports.value = reports.value.filter((r) =>
        r.name.toLowerCase().includes(filter.toLowerCase())
      )
    }

    const resetSearch = () => {
      filteredReports.value = []
    }

    return {
      filteredReports,
      reports,
      setEditReport,
      filterReports,
      resetSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.report__list {
  align-items: center;

  .back {
    height: 40px;
    width: calc(90% - 45px);
    margin-left: 20px;
    padding: 0 15px;
    border-radius: 10px;
    align-items: center;

    i {
      margin-right: 10px;
      color: var(--dark-2);
    }
  }

  .search {
    height: 40px;
    width: calc(90% - 16px);
    margin: 0 0 0 20px;
  }
}
</style>
